nav {
  border-bottom: 2px solid white;

  .keystone-logo {
    width: 130px;
  }

  .fa-sign-out-alt {
    width: 20px !important;
    height: 20px;
  }

  .name-of-user {
    color: white;
    position: absolute;
    right: 20px;
    top: 16px;
  }

  @media (min-width: 992px) {
    .logouto {
      width: 146px;
    }
    svg {
      float: right;
    }

    .empty-spacer-nav {
      width: 146px;
    }
  }

  .login-register {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .nav-link2 {
    color: white !important;
    &:hover {
      color: #608eb6 !important;
      text-decoration: none;
    }
  }
}
