.proj-manag-container {
  width: 900px;
  margin: 100px auto;

  .projects-table-container {
    display: grid;
    grid-template-columns: 350px 200px 350px;
  }

  .table-container {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .new-project-container {
    max-height: 180px;
    display: grid;
    grid-gap: 1px;
    align-items: center;
    grid-template-rows: 50px auto;
    grid-column-start: 3;
    grid-column-end: 3;
    border: 1px solid #000;
    border-radius: 5px;
    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid black;
      text {
        background-color: rgb(255, 255, 255);
        color: rgb(70, 70, 70);
      }
    }

    .project-input-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      #project-input-field {
        margin-bottom: 10px;
      }

      #project-add-button {
        box-shadow: -3px 5px 5px 0px rgba(113, 111, 111, 0.68);
      }

      #project-add-button:active {
        transform: translate(-2px, 1px);
        box-shadow: 0 0 0 0;
      }
    }
  }

  table {
    width: 350px;
    max-height: 400px;
    border-spacing: 0;
    border-radius: 5px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #666; /* this draws the table border  */
  }

  th {
    background: #000000;
    font-size: 12px;
    color: rgb(250, 250, 250);
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  td {
    padding: 5px 10px;
    text-align: left;
    border: 1px solid black;
  }

  tr:not(:nth-child(1)):hover {
    background-color: #2493f5;
  }
}
