.error-text {
  margin-top: 20px;
}

.shake {
  animation: shake 0.2s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  10% {
    transform: translate(-2px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-4px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(4px, 3px) rotate(0deg);
  }
  40% {
    transform: translate(2px, -2px) rotate(1deg);
  }
  50% {
    transform: translate(-2px, 1px) rotate(-1deg);
  }
  60% {
    transform: translate(-4px, 0px) rotate(0deg);
  }
  70% {
    transform: translate(4px, 2px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -2px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 3px) rotate(0deg);
  }
  100% {
    transform: translate(2px, -3px) rotate(-1deg);
  }
}
