.project-keytime {
  max-width: 100%;
  max-height: 212.5px;
  margin: 5px auto;
}

@media (min-width: 991px) {
  .project-keytime {
    max-height: 425px;
  }
}

.img-container-keytime {
  padding: 10px 20px;
}
