.proj-comp-container {
  width: 900px;
  margin: 100px auto;

  .projects-table-container {
    display: grid;
    grid-template-columns: 350px 200px 350px;
  }

  .table-container1 {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .table-container2 {
    grid-column-start: 3;
    grid-column-end: 3;
  }

  table {
    width: 300px;
    max-height: 400px;
    border-spacing: 0;
    border-radius: 5px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #666; /* this draws the table border  */
  }

  th {
    background: #000000;
    font-size: 12px;
    color: rgb(250, 250, 250);
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  td {
    padding: 5px 10px;
    text-align: left;
    border: 1px solid black;
  }

  tr:not(:nth-child(1)):hover {
    background-color: #2493f5;
    cursor: pointer;
  }
}
