.timesheet-container {
  max-width: 1200px;
  padding: 5px 5px;
  margin: 20px auto;
  .header-days {
    text-align: center;
  }

  .project-cell {
    width: 170px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .limit-height {
    height: 20px;
  }
  .weekend {
    background-color: rgba(188, 189, 189, 0.762);
    color: rgb(94, 94, 94);
  }

  .daycell {
    text-align: center;
    input {
      text-align: center;
      width: 18px;
    }
  }

  .legends {
    width: 95%;
    margin: 5px 10px;
    display: flex;
    justify-content: flex-start;
  }

  .timesheet-locked-status {
    height: 30px;
    padding: 10px 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .totaldays {
    margin: 0 0;
    display: flex;
    align-items: center;
    height: 56px;
    justify-content: flex-end;
  }

  .totaldays-validated {
    margin: 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .total-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 250px;
  }

  .save-timesheet {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-right: 10px;

    button {
      border-radius: 10px;
      outline: none;
      height: 30px;
      width: 50px;
      margin-right: 5px;
    }
    button:hover {
      border: solid 1px black;
    }

    button:disabled {
      opacity: 0.5;
    }

    .savebutton {
      background-color: #608eb6;
      path {
        fill: white;
      }
    }

    .validatebutton {
      background-color: #5cb14a;
      path {
        fill: white;
      }
    }

    .exportbutton {
      background-color: rgb(103, 103, 103);
      path {
        fill: white;
      }
    }
  }
}
