.timesheets {
  width: 100%;
  margin: 0 auto;
}

#custom-datepicker-keystone {
  margin-top: 20px;
  text-align: center;
  width: 120px;
  outline: none;
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 #15466f;

  &:focus {
    outline: none;
  }
}

.flex-container-export {
  max-width: 1200px;
  margin-top: 15px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 5px 5px;
  align-items: flex-end;
  justify-content: flex-end;
}

.mass-export {
  background-color: rgb(103, 103, 103);
  width: 100px;
  height: 40px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: rgb(82, 82, 82);
    box-shadow: 2px 2px 3px 0 grey;
    transform: translateY(-2px);
  }

  &:active  {
    transform: translateY(2px);
  }
}

.sheet-add {
  width: 230px;
  height: 40px;
  color: white;
  display: flex;
  margin: 20px auto;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgb(50, 141, 238);
  cursor: pointer;
  animation: pulsate 1.5s linear infinite;
}

.add-remaining-sheets {
  width: 210px;
  height: 40px;
  color: white;
  display: flex;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #608eb6;
  cursor: pointer;
  animation: pulsate 1.2s ease-in-out infinite;
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 3px 0 grey;
    transform: translateY(-2px);
  }

  &:active  {
    transform: translateY(2px);
  }
}

.add-remaining-sheets:before {
  content: 'New projects available';
}

@keyframes pulsate {
  0% {
    background-color: #608eb6;
  }
  50% {
    background-color: #6fa3d0;
  }
  100% {
    background-color: #608eb6;
  }
}
