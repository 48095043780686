.admin-title {
  margin-top: 20px;
}

#custom-datepicker-admin {
  margin-top: 20px;
  text-align: center;
  width: 80px;
  outline: none;
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 0 #15466f;

  &:focus {
    outline: none;
  }
}
