.timesheet-container {
  max-width: 1200px;
  padding: 5px 5px;
  margin: 20px auto;

  .header-days {
    text-align: center;
  }

  .project-cell {
    width: 170px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .limit-height {
    height: 20px;
  }
  .weekend {
    background-color: rgba(188, 189, 189, 0.762);
    color: rgb(94, 94, 94);
  }
  .daycell {
    text-align: center;
    input {
      text-align: center;
      width: 18px;
    }
  }

  .legends {
    width: 95%;
    margin: 5px 10px;
    display: flex;
    justify-content: flex-start;
  }

  .weekend-legend {
    background-color: rgba(188, 189, 189, 0.762);
    flex-basis: 10%;
    height: 30px;
    padding: 10px 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: 0px 1px 2px 1px rgba(169, 168, 168, 0.276);
  }

  .totaldays {
    margin: 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .totaldays-validated {
    background-color: rgba(92, 206, 69, 0.741);
    margin: 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .total-text {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 250px;
  }

  .save-timesheet {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-right: 10px;

    button {
      border-radius: 10px;
      outline: none;
      height: 30px;
      width: 50px;
      margin-right: 5px;
    }
  }
}
